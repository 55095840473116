@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');


* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

html {
   scroll-behavior: smooth;
}


body {
   /* background-color: black;
    background-image: url("../public/wallpaper.avif");
    width: 100%;
    height: 100vh;
    color: #fff;  */
}

.app-body {
   font-family: 'Nunito', sans-serif;
}

.app-header {
   color: #fff;
}

h2 {
   text-align: center;
   margin: 80px 0px 10px 0px;
   color: black;
   font-weight: 800;
   padding: 0px 20px;
}

h3 {
   text-align: center;
   margin-bottom: 3px;
   color: blue;
   padding: 0px 20px;
}

.header {
   background-color: black;
   color: #fff;
   padding: 30px;

   display: flex;
   align-items: center;
   justify-content: space-around;

   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 10;

   /* margin-top: 30px; */
}

.logo {
   line-height: 0.8;
   font-weight: 900;

   h5 {
      margin-left: 40px;
   }
}

.first-logo {
   color: cyan;
}

.d-logo {
   color: #E85C0D;
}

.u-logo {
   color: #C7253E;
}

.k-logo {
   color: #821131;
}

.o-logo {
   color: #2E073F;
}

/* logo-h5 */

.l-logo {
   color: cyan;
}

.e-logo {
   color: cyan;
}

.a-logo {
   color: #E85C0D;
}

.r-logo {
   color: #E85C0D;
}

.n-logo {
   color: #C7253E;
}


.i-logo {
   color: #C7253E;
}

.g-logo {
   color: #821131;
}

.b-logo {
   color: #821131;
}

.last-logo {
   color: #821131;
}

/* Nav */

.nav {
   color: #fff;
}

.nav ul {
   list-style-type: none;
   display: flex;
}

.nav ul a {
   padding: 0 10px;
}

.nav-mobile {
   position: fixed;
   overflow-y: hidden;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   /* background-color: rgba(45, 43, 70, 0.9); */
   background-color: rgba(255, 255, 255, 0.2);
   /* Light frosted glass effect */
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.3);
   transition: 0.3s;
   z-index: 999;

}

.nav-mobile .mobile-nav-toggle {
   position: absolute;
   top: 45px;
   right: 25px;
}

.nav-mobile ul {
   display: block;
   position: absolute;
   top: 80px;
   right: 15px;
   bottom: 20px;
   left: 150px;
   padding: 10px 0;
   background-color: #fff;
   overflow-y: auto;
   transition: 0.3s;
}

.nav-mobile a,
.nav-mobile a:focus {
   padding: 30px 20px;
   font-size: 20px;
   color: black;
}

.nav-mobile a:hover,
.nav-mobile .active {
   color: cyan;
}

.mobile-nav-toggle {
   color: #fff;
   font-size: 28px;
   cursor: pointer;
   display: none;
   line-height: 0;
   transition: 0.5s;
}

.mobile-nav-toggle.fa-times {
   color: #fff;
}


ul {
   list-style-type: none;
   display: flex;
}

a {
   text-decoration: none;
   color: #fff;
   cursor: pointer;
}

ul li {
   padding: 0 10px;
}

/* .nav-link{
      order: 0;
   } */

.nav-link:hover {
   color: cyan;
   cursor: pointer;
}

.active {
   color: cyan;
}

ul li i:hover {
   color: cyan;
   cursor: pointer;
}

.hero {
   background-color: black;
   background-image: url("../public/wallpaper.avif");
   color: #fff;
   font-family: 'Nunito', sans-serif;

   /* background-color: blue; */
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;

   flex-direction: column;
   text-align: center;
   gap: 50px;
   padding: 0px 20px;
}

.welcome {
   /* display: flex; */

}

.welcome {
   font-family: 'Pacifico', cursive;
}

.btn {
   padding: 5px 12px;
   background-color: #fff;
   border: 2px solid gray;
   border-radius: 8px;
   color: black;
   transition: transform 2s ease-out;
}

.btn:hover {
   transform: scale(1.1);
   color: #fff;
   background-color: black;
   border: 2px solid #fff;
   cursor: pointer;
}

/* Audio and others */

.audio-list-wrapper {
   padding: 180px 20px 10px;
   height: 100vh;
   background-color: #ccc;
}

.card {
   max-width: 600px;
   height: 190px;
   display: flex;
   background-color: #fff;
   gap: 10px;
   /* border-bottom: 1px solid #808080; */
   margin: 0 auto;
   margin-bottom: 30px;
   border-radius: 5px;
}

.card-image-desc {
   /* width: 280px; */
   /* height: 180px; */
   width: 45%;
   background-color: aqua;
   /* object-fit: contain; */
   /* object-fit: cover; */
   height: 100%;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
}

.card-image-desc img {
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
}

.card-des {
   display: flex;
   flex-direction: column;
   padding: 10px 0px;
   justify-content: space-between;
   width: 55%;
   height: 100%;
   /* background-color: aquamarine; */
}

.card-title {
   font-size: 20px;
   font-weight: 900;
}

.card-date {
   color: #808080;
   /* font-style: italic; */
   font-family: 'Pacifico', cursive;
   font-size: 15px;
}

.click-to-get-book {
   font-size: 12px;
   margin-top: 10px;
   border: 1px solid #ccc;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   padding: 5px;
   width: fit-content;
   cursor: pointer;
   color: black;
}

.gat{
   /* color: black; */
}

.play-icon-wrapper {
   /* background-color: #ccc; */
   background-color: #808080;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 45px;
   height: 45px;
   border-radius: 50px;
   cursor: pointer;
   border: 2px soild #1491D8;
}

.play-icon {
   color: cyan;
   /* color: #1491D8; */
   font-size: 25px;
}


/* About-page */

.about {
   margin-top: 95px;
   align-items: center;
   height: 100%;
   width: 100%;
   background-color: black;
   display: flex;
   flex-direction: column;
   background-image: url("../public/black-bg.jpeg") center center / contain no-repeat;

   color: #fff;

   p {
      flex: 1;
      max-width: 900px;
      padding: 40px;
      line-height: 2rem;
      font-family: 'Nunito', sans-serif;
      font-size: 20px;
      margin: 0 auto;
      height: 100%;
   }

   a {
      color: cyan;
   }
}


/* More-page */

.more {
   /* margin-top: 110px; */
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;
}

/* Footer-page */

.footer {
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
   background-color: #fff;
   color: black;
   padding: 20px 10px;
   width: 100%;
   font-weight: bold;

}

.footer a {
   color: black;
}

.footer li a {
   color: black;
   /* font-size: 25px; */
}


.footer-media {
   max-width: 100px;
   text-align: center;
   margin: 10px auto;
}

.footer-contact {
   max-width: 100px;
   display: flex;
   justify-content: center;
   margin: 0 auto;
}

.footer-email {
   margin-left: 5px;
   font-style: italic;
   text-decoration: none;
   font-weight: 100;

}

@media screen and (max-width: 991px) {

   .header {
      justify-content: space-between;
   }

   .mobile-nav-toggle {
      display: block;
   }

   .nav ul {
      display: none;
   }

   .media {
      display: none;
   }

   .nav-mobile ul {
      display: block;
   }

   .nav-mobile ul li {
      margin: 20px 0;
      font-weight: 700;
   }

   .hero-name {
      font-size: 20px;
   }

   .audio-list-wrapper {
      padding: 150px 20px 10px;
   }

   .cards {
      flex-direction: column;
   }

   .card {
      height: 160px;
      position: relative;

   }

   .card-des {
      padding: 7px 0px;
      justify-content: flex-start;
   }

   .card-title {
      font-size: 18px;
   }

   .card-narration {
      font-size: 16px;
   }


   .card-image-desc {
      /* max-width: 250px; */
      /* max-height: 150px; */
      /* position: relative; */
   }

   .play-icon-wrapper {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 15%;
      top: 35%;
      background-color: transparent;
      border: 2px solid #fff;
   }

   .play-icon {
      font-size: 30px;
   }

}

@media screen and (max-width: 430px) {}


/* More Page */

.more-wrapper {
   /* background-color: gray; */
   /* margin-top: 90px; */
   /* height: 100%; */
   /* padding: 20px; */

   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   padding: 20px;
   margin-top: 65px;
}

.frame-wrapper {
   width: 100%;
   max-width: 450px;
   text-align: center;
}

iframe {
   width: 100%;
   max-width: 100%;
   height: auto;
   aspect-ratio: 16 / 9;
}

/* .fram-header{
   text-align: left;
} */

.frame-card {
   /* display: flex; */
   /* flex-direction: column; */
   /* align-items: center; */
   /* margin: 50px 0px; */
}

.iframeClass {
   /* max-width: 400px; */
}